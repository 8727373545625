@import "~antd/dist/antd.css";

html {
  height: 100%;
}
body {
  min-height: 100%;
  overflow: auto;
  padding: 0;
  margin: 0;
  background: #f8fafc;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
}
